<template>
  <section>
    <div class="container-fluid" :class="{'skeleton-box': loading}">
      <div class="d-flex justify-content-between flex-wrap">
        <h5 class="secondary-text-color">VISÃO CASH FLOWS</h5>
        <datepicker 
          v-model="selectedMonth" 
          :minimumView="'month'" 
          :maximumView="'year'" 
          :format="customFormatter" 
          :disabledDates="disabledDates" 
          :language="ptBR"
          :calendar-button="true"
          :calendar-button-icon="'bi bi-calendar3'"
          :class="{'skeleton-box-hide': loading}"
          >
        </datepicker>
      </div>
      <div class="row my-5" id="cashflow-info" :class="{'skeleton-box-hide': loading}">
        <div class="col-lg-2 col-md-12 col-sm-12 text-center">
          <p><b>{{readableIncome}} €</b></p>
            <h4 class="secondary-text-color"><b>Rendas</b></h4>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12 text-center">
          <h1 class="secondary-text-color"><b>-</b></h1>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 text-center">
          <p><b>{{readableAccountability}} €</b></p>
            <h4 class="secondary-text-color"><b>Prestações</b></h4>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12 text-center">
          <h1 class="secondary-text-color"><b>-</b></h1>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 text-center">
          <p><b>{{readableTotalCosts}} €</b></p>
            <h4 class="secondary-text-color"><b>Custos</b></h4>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12 text-center">
          <h1 class="secondary-text-color"><b>=</b></h1>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-12 text-center">
          <p><b>{{totalCash}} €</b></p>
            <h4 class="secondary-text-color"><b>Total Cash</b></h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from 'vuejs-datepicker';
var moment = require('moment');
import {ptBR} from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker,
  },
  data: function (){
    return {
      selectedMonth: null,
      ptBR: ptBR,
      disabledDates: {
        from: new Date()
      },
    }
  },
  props: { value: String },
  mounted: function()
  {
    var date = new Date ();
    date.setMonth(date.getMonth()-1);
    this.selectedMonth = date.toISOString().substr(0, 7);  
  },
  computed:{
    ...mapState({ 
      statisticsOutput: state => state.statistics.dynamicOutput,
    }),
    loading: function()
    {
      return this.statisticsOutput == null;
    },
    statistics: function() {
      return this.statisticsOutput && this.statisticsOutput.data ? this.statisticsOutput.data : {};   
    },
    readableIncome: function()
    {
      return this.formatNumber(this.statistics.income)
    },
    readableAccountability: function()
    {
      return this.formatNumber(this.statistics.accountability)
    },
    totalCosts: function () 
    {
      return this.statistics.initialCost + this.statistics.costs
    },
    readableTotalCosts: function()
      {
        return this.formatNumber(this.totalCosts);
      }, 
    totalCash: function () {
      return this.formatNumber(this.statistics.income - this.statistics.accountability - this.totalCosts)
    },
  },
  methods:{
    formatNumber(value)
    {
      if(value || value == 0){
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
    customFormatter(date) {
      moment.locale('pt');
      return moment(date).format(`MMMM-YYYY`);
    }
      
  },
  watch:
  {
    selectedMonth: function(val)
    {
      let date = new Date(val);
      let isoString = date.toISOString().substr(0, 7);
      this.$emit("input", isoString);
    },
  }
}

</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

h5{
  margin-left: 1rem;
  margin-right: 1rem;
}
#cashflow-info{
  margin-left: 4rem;
  margin-right: 4rem;
}

input[type="month"]
{
  color: dimgray;
  border-color: dimgray;
}

.skeleton-box
{
  min-height: 25vh;
}

@media only screen and (max-width: 991px) {
  #month-filter{
    text-align: center;
  }

  h5
  {
    text-align: center;
    margin-left: 0;
  }

  .skeleton-box
  {
    min-height: 80vh;
  }
}

</style>