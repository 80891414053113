<template>
  <section>
    <div class="container-fluid" :class="{'skeleton-box': loading}">
      <div class="d-flex justify-content-between flex-wrap">
        <h5 class="secondary-text-color">VISÃO IMÓVEIS</h5>
        <!-- <datepicker 
          v-model="selectedMonth" 
          :minimumView="'month'" 
          :maximumView="'year'" 
          :format="customFormatter" 
          :disabledDates="disabledDates" 
          :language="ptBR"
          :calendar-button="true"
          :calendar-button-icon="'bi bi-calendar3'"
          :class="{'skeleton-box-hide': loading}"
          >
        </datepicker> -->
      </div>
      <div v-if="statistics" class="row my-3" :class="{'skeleton-box-hide': loading}">
        <!-- Total number -->
        <SquareBlock class="col-lg-3 col-md-6 col-sm-12 my-3 my-lg-0" icon="dashboard/iconHome.png" :title="statistics.totalModules.toString()" subtitle="Nr. total de imóveis"></SquareBlock>
        <!-- Rented -->
        <SquareBlock class="col-lg-3 col-md-6 col-sm-12 my-3 my-lg-0" icon="dashboard/iconHome.png" :title="statistics.rented.toString()" subtitle="Arrendados"></SquareBlock>
        <!-- AirBnB -->
        <SquareBlock class="col-lg-3 col-md-6 col-sm-12 my-3 my-lg-0" icon="dashboard/iconAirBnb.png" :title="statistics.airBnB.toString()" subtitle="AirBnb ou outros"></SquareBlock>
        <!-- No cashflows -->
        <SquareBlock class="col-lg-3 col-md-6 col-sm-12 my-3 my-lg-0" icon="dashboard/iconRend.png" :title="statistics.noIncome.toString()" subtitle="Sem rendimentos"></SquareBlock>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import SquareBlock from "@/controls/SquareBlock.vue";
// import Datepicker from 'vuejs-datepicker';

export default {
  components: { SquareBlock },
  // components: { SquareBlock, Datepicker },
  computed:{
    ...mapState({ 
      staticOutput: state => state.statistics.staticOutput,
    }),
    loading: function()
    {
      return this.staticOutput == null;
    },
    statistics: function() {
      return this.staticOutput && this.staticOutput.data ? this.staticOutput.data : null;
    },
  }
}

</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

/* h5{
  margin-left: 1rem;
} */

.skeleton-box
{
  min-height: 25vh;
}

@media only screen and (max-width: 991px) {
  h5{
    text-align: center;
    margin-left: 0;
  }

  .skeleton-box
  {
    min-height: 80vh;
  }
}
</style>