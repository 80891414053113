<template>
  <section>
    <div class="container-fluid" :class="{'skeleton-box': loading}">
      <h5 class="secondary-text-color">POSIÇÃO FINANCEIRA</h5>

      <div class="row d-flex align-items-center flex-wrap" :class="{'skeleton-box-hide': loading}">
        <!-- Total purchases -->
        <SquareBlock class="col-lg-3 col-md-12 col-sm-12 my-3 my-lg-0" icon="dashboard/iconMarket.png" :title="`${readablePurchasesAmount}€`" subtitle="Total Compras"></SquareBlock>
        <!-- Position -->
        <div class="col-lg-6 col-md-12 col-sm-12 my-3 my-lg-0">
          <vc-donut
            background="white" foreground="grey"
            :size="150" unit="px" :thickness="40"
            has-legend legend-placement="bottom"
            :sections="sections" :total="100"
            :start-angle="30" :auto-adjust-text-size="true">
            <!-- <h6>100%</h6> -->
          </vc-donut>
        </div>
        <!-- Total cash -->
        <div class="col-lg-3 col-md-12 col-sm-12 my-3 my-lg-0 text-center">
            <p><b>{{totalCash}} €</b></p>
            <h4 class="secondary-text-color"><b>Total Cash</b></h4>
        </div>
      </div>
    </div>
    
  </section>
</template>
<script>
import { mapState } from "vuex";
import SquareBlock from "@/controls/SquareBlock.vue";

  export default {
    components: { SquareBlock },
    computed:{
      ...mapState({ 
        statisticsOutput: state => state.statistics.staticOutput,
      }),
      loading: function()
      {
        return this.statisticsOutput == null;
      },
      statistics: function() {
        return this.statisticsOutput && this.statisticsOutput.data && this.statisticsOutput.data ? this.statisticsOutput.data : {};
      },
      cashflow: function(){
        return this.statisticsOutput && this.statisticsOutput.data && this.statisticsOutput.data.cashFlow ? this.statisticsOutput.data.cashFlow : {};
      },
      readablePurchasesAmount: function()
        {
          return this.formatNumber(this.statistics.totalPurchasesAmount)
        },
      totalCosts : function () {
        return this.cashflow ? this.cashflow.initialCost + this.cashflow.costs : 0;
      },
      totalCash : function () {
        return this.cashflow ? this.formatNumber(this.cashflow.income - this.cashflow.accountability - this.totalCosts) : 0;
      },
      liquid: function()
      {
        return this.cashflow ? this.formatNumber(this.cashflow.accountability) : 0;
      },
      nonLiquid : function () {
        return this.statistics && this.cashflow ? this.formatNumber(this.statistics.totalFinancingAmount - this.cashflow.accountability) : 0;
      },
      percentNonLiquid : function () {
        return this.percentLiquid > 0 ? 100 - this.percentLiquid : 0;
      },
      percentLiquid : function() {
        return this.cashflow && this.statistics ? (this.cashflow.accountability * 100) / this.statistics.totalFinancingAmount : 0;
      },
      sections: function()
      {
        var result = [
          { label: `Liquidado: ${this.liquid}€ (${this.percentLiquid.toFixed(2)}%)`, value: this.percentLiquid, color: 'rgb(78, 84, 200)' },
          { label: `Por liquidar: ${this.nonLiquid}€ (${this.percentNonLiquid.toFixed(2)}%)`, value: this.percentNonLiquid, color: 'rgb(143, 148, 251)' },
        ];
        return result;
      } 
    },
    methods:{
      formatNumber(value)
      {
        if(value || value == 0){
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
      } 
    },
  };
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}
/* 
h5{
  margin-left: 1rem;
} */

.skeleton-box
{
  min-height: 25vh;
}

@media only screen and (max-width: 991px) {
  .skeleton-box
  {
    min-height: 80vh;
  }
}

>>>.cdc-legend
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>