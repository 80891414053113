<template>
  <section>
    <div class="container-fluid">
      <div class="row h-100">
        <div class="h-100 col-lg-6 col-sm-12 d-flex flex-column justify-content-center">   
          <span><b>Olá,</b> <span v-if="userInfo" class="secondary-text-color"><b>{{userInfo.name}}</b></span></span>
          <router-link :to="{ name: 'UsersList' }" class="router-link-decoration"><p class="validate-data">Verifique aqui os seus dados</p></router-link>
        </div>
        <div class="col-lg-6">
          <img src="../../assets/dashboard/people.png" alt="People" class="people-Img">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
computed: 
  {
    ...mapState('account', ['user']),
    userInfo: function()
    {
      return this.user && this.user.data ? this.user.data : null
    }
  },
}
</script>

<style scoped>
.router-link-decoration{
  text-decoration: none !important;
}

.container-fluid{
  background-color: white;
  border-radius: 20px;
  width: 55vw;
  height: 8em;
  margin-top: 7rem;
}

.validate-data{
  color: rgb(187, 185, 185);
}

.people-Img{
  margin-top: -6em;
  width: 13rem;
}
</style>