<template>
<section class="container py-4">
  <!-- Desktop only -->
  <DashboardHeader class="d-none d-lg-block"/>
  <DashboardFinances class="d-none d-lg-block my-4"/>
  <DashboardCashFlow class="d-none d-lg-block my-4" v-model="selectedMonth"/>
  <DashboardHousing :invokeGetStatistics="false" class="d-none d-lg-block my-4"/>
  
  <!-- Mobile only -->
  <CTabs variant="tabs" class="d-block d-lg-none" :active-tab="0">
    <CTab title="Finanças">
      <DashboardFinances :invokeGetStatistics="false" class="mt-4"/>
    </CTab>
    <CTab title="Cash flows">
      <DashboardCashFlow :invokeGetStatistics="false" class="mt-4" v-model="selectedMonth"/>
    </CTab>
    <CTab title="Imóveis">
      <DashboardHousing :invokeGetStatistics="false" class="mt-4"/>
    </CTab>
  </CTabs>
</section>
</template>

<script>
import { mapActions } from "vuex";

import DashboardFinances from "./DashboardFinances.vue";
import DashboardCashFlow from "./DashboardCashFlow.vue";
import DashboardHeader from "./DashboardHeader.vue";
import DashboardHousing from "./DashboardHousing.vue";
export default {
  components: {
      DashboardFinances,
      DashboardCashFlow,
      DashboardHeader,
      DashboardHousing
  },
  methods:
  {
    ...mapActions(
    { 
      getStatistics: 'statistics/getStatic',
      getDynamics: 'statistics/getDynamic' 
    })
  },
  data: function (){
    return {
      selectedMonth: null,
    }
  },
  mounted: function()
  {
    // This will invoke the invoke of getDynamics
    var date = new Date ();
    date.setMonth(date.getMonth()-1);
    this.selectedMonth = date.toISOString().substr(0, 7);  

    // Get static statistic
    this.getStatistics();
  },
  watch:{
    selectedMonth: function () 
    {
      var queryParams = {date: this.selectedMonth};
      this.getDynamics(queryParams);
    },
  }
}
</script>

<style scoped>
>>>.nav-link{
  color: rgb(78, 84, 200) !important;
}
</style>